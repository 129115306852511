@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --color-primary: #168dff;
  --color-secondary: #093866;
  --color-accent: #ff4081;
  --color-accent-shade: #fbca85;
  --color-body: #2f2f2f;
  --color-background-body: var(--color-primary);
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: 67%;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--color-background-body);
  font-family: Jost, Arial, Helvetica, sans-serif;
  font-size: 2.4rem;
  line-height: 1.5;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
}

h1,
h2,
h3 {
}

h1 {
  font-size: 2.8rem !important;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 2rem !important;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

h3 {
  font-size: 1.7rem !important;
  font-weight: 500;
  margin-bottom: 0.3rem;
}

p {
  margin-top: 0;
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 3.2rem;
  }

  h2 {
    font-size: 2.2rem;
  }

  h3 {
    font-size: 2.2rem;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clickable {
  cursor: pointer;
}

.tldr-three-lines-overflow-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

mark {
  color: #000000;
  background-color: #ffff00;
}

.debug-border {
  border: 1px solid #000000;
}

@media screen and (max-width: 992px) {
  .hide-on-small-screen {
    display: none;
  }
}

.main-body-row {
}

@media screen and (min-width: 768px) {
  .main-body-row {
    padding: 0;
  }
}

.main-container-card {
  border-radius: 0;
}

@media screen and (min-width: 768px) {
  .main-container-card {
    border-radius: 8px;
  }
}

.responsive-card-body {
  padding: 8px !important;
}

@media screen and (min-width: 768px) {
  .responsive-card-body {
    padding: 8px !important;
  }
}

@media screen and (min-width: 992px) {
  .responsive-card-body {
    padding: 10px !important;
  }
}

@media screen and (min-width: 1200px) {
  .responsive-card-body {
    padding: 16px !important;
  }
}

@media screen and (min-width: 1400px) {
  .responsive-card-body {
    padding: 18px !important;
  }
}

@media screen and (max-width: 768px) {
  .square-border-on-small-screen {
    border-radius: 0;
  }
}

.overlay-open {
  overflow: hidden;
}

.iframe-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.desktop-only {
  display: block;
}

.mobile-only {
  display: none;
}

@media (max-width: 767px) {
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }
}

.tldr-message-highlight {
  border: 2px solid #ffff00;
  background-color: #ffff00;
  color: #000000;
}

.tldr-message-highlight-child {
  background-color: #ffff00;
  color: #000000;
}
